var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-cart slide-holder"},[_c('div',{staticClass:"row",class:{
      'text-left': _vm.$i18n.locale == 'en',
      'text-right': _vm.$i18n.locale == 'ar',
    }},[_c('div',{staticClass:"col-xl-6 col-lg-12 col-md-12 col-sm-12"},[(_vm.slider.current_main_image_path !== null)?_c('div',{staticClass:"time-holder"},[_c('b-img',{staticClass:"offer-image w-100",attrs:{"src":_vm.slider.current_main_image_path}}),(_vm.dealType)?_c('div',{staticClass:"time-count"},[_vm._v(" "+_vm._s(_vm.dealType)+" ")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"col-xl-6 col-lg-12 col-md-12 col-sm-12 d-flex flex-column align-items-start justify-content-between"},[(_vm.slider)?_c('div',[_c('div',{staticClass:"card-info"},[(_vm.slider.product && _vm.slider.product.title && _vm.dealType)?_c('h4',{staticClass:"main-header my-2"},[_c('router-link',{attrs:{"to":{
                path: '/details',
                query: { id: ("" + (_vm.slider.id)), type: _vm.dealType },
              }}},[_vm._v(" "+_vm._s(_vm.slider.product.title)+" ")])],1):(_vm.slider.product && _vm.slider.product.title && !_vm.dealType)?_c('h4',{staticClass:"main-header my-2"},[_c('router-link',{attrs:{"to":{ path: '/details', query: { id: ("" + (_vm.slider.id)) } }}},[_vm._v(" "+_vm._s(_vm.slider.product.title)+" ")])],1):_vm._e(),(
              _vm.slider.product_details_by_type &&
              _vm.slider.product_details_by_type.currency_based_customer_price
            )?_c('h5',{staticClass:"price m-0"},[_vm._v(" "+_vm._s(_vm._f("fixedCurrency")(_vm.slider.product_details_by_type.currency_based_customer_price))+" "+_vm._s(_vm.currency)+" ")]):_vm._e(),(
              _vm.slider.product_details_by_type &&
              _vm.slider.product_details_by_type
                .currency_based_price_before_discount &&
              _vm.slider.product_details_by_type
                .currency_based_price_before_discount >
                _vm.slider.product_details_by_type.currency_based_customer_price
            )?_c('p',{staticClass:"price-after price m-0"},[_vm._v(" "+_vm._s(_vm._f("fixedCurrency")(_vm.slider.product_details_by_type .currency_based_price_before_discount))+" "+_vm._s(_vm.currency)+" ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"row mb-3 align-items-center",class:{ 'flex-column w-100': _vm.add_to_cart !== true }},[_c('div',{staticClass:"col-12 px-1"},[(_vm.slider)?_c('router-link',{attrs:{"to":{
              path: '/details',
              query: { id: ("" + (_vm.slider.id)), type: _vm.dealType },
            }}},[_c('button',{staticClass:"outline-main"},[_vm._v(" "+_vm._s(_vm.$t("profile.details"))+" ")])]):_vm._e()],1),(_vm.slider.product_details_by_type.add_type == 'visit_website')?_c('button',{staticClass:"mx-2 border-main"},[_c('a',{attrs:{"href":_vm.slider.conference_register_link,"target":"_blank"}},[_c('h5',{staticClass:"p-2 m-0"},[_vm._v(_vm._s(_vm.$t("login.visitWebsite")))])])]):_c('div',{},[(
              (_vm.add_to_cart == true &&
                _vm.slider.product_details_by_type.add_type === 'cart') ||
              (_vm.add_to_cart == true &&
                _vm.slider.product_details_by_type.add_type === 'both')
            )?_c('div',{staticClass:"col-12 px-1"},[_c('a',[_c('button',{staticClass:"bg-main text-white",on:{"click":function($event){return _vm.addToCart(_vm.slider)}}},[_vm._v(" "+_vm._s(_vm.$t("items.addToCart"))+" ")])])]):_vm._e()])])])]),(_vm.buttonTrue == true)?_c('button',{staticClass:"border-main main-color br-5 btn btn-block mt-2",on:{"click":function($event){return _vm.getOffer(_vm.slider)}}},[_vm._v(" "+_vm._s(_vm.$t("singleProduct.getOffer"))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }