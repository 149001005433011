export default {
  needEmailContact: "لتغير البريد الالكتروني اضغط هنا.",
  // needEmailContact: "لتغير البريد الالكتروني اتصل بنا.",
  needPhoneContact: "لتغير الهاتف اضغط هنا.",
  // needPhoneContact: "لتغير الهاتف اتصل بنا.",
  needCompanyContact: "لتغير الشركة اتصل بنا.",
  //Header
  completeAccount: "اكمل حسابك",
  completeMessage:
    "يرجى إكمال التسجيل للحصول على حق الوصول الكامل إلى ترخيص المشتري الآن يمكنك فقط الوصول المحدود.",
  completeLink: "انقر هنا لإكمال التسجيل",
  completeRate: "نسبة اكتمال بيانات التسجيل",
  customerName: "اسم العميل",
  customerEmail: "بريد العميل",
  // side Menu
  myProfile: "حسابي",
  shop: "المتجر",
  shoppingCart: "السلة",
  accountInformation: "معلومات الحساب ",
  accountInfo: "معلومات الحساب ",
  buyerInformation: "معلومات الحساب ",
  addressInfo: "معلومات العنوان",
  // categories
  welcomeAgain: "مرحبا بك مجددا",
  categories: "الاقسام",
  // shopping card
  totalPrice: "الاجمالي",
  quantity: "الكمية",
  price: "السعر",
  itemPrice: "سعر المنتج",
  product: "المنتج",
  // Subscribe Newsletter
  subscribeNewsletter: "الاشتراك بالنشرة الدورية",
  exclusiveOffers: "كن أول من يعرض على العروض الحصرية والقسائم",
  notSubscribed: "لست مشترك في النشرة",
  subscribe: "اشتراك",
  // favorite
  favorite: "المفضلة",
  b2bFav: "قائمة الانتظار",
  // changePassword
  changePassword: "تغير كلمه السر",
  changePasswordMessage: "تم تغيير كلمة السر بنجاح",
  currentPassword: "كلمة المرور الحالية",
  NewPassword: "كلمة المرور الجديدة",
  // adress book
  addressBook: "سجل العناوين",
  shippingAddress: "بيانات التوصيل",
  newAddress: "عنوان جديد",
  country: "البلد",
  countryOrigin: "بلد المنشأ",
  region: "المدينه",
  city: "الحى",
  streetNumber: "الشارع",
  newStreetNumber: "الشارع",
  homeNumber: "رقم المنزل",
  avenue: "السبيل",
  floor: "الدور",
  blockNumber: "شقه",
  postCode: "الرمز البريدى",
  zipCode: "الرمز البريدى",
  note: "ملاحظات",
  // b2b
  businessInformation: "معلومات العمل",
  RegistrationNumber: "رقم التسجيل",
  productReviews: "مراجعات منتجاتي",
  construction: " تاريخ الشراء",
  evaluation: "تاريخ التقييم",
  review: "مراجعة",
  actions: "احداث",
  viewDetails: "عرض التفاصيل",
  productName: "اسم المنتج",
  productImage: "صورة المنتج",
  save: "حفظ",
  // Documents
  documents: "المستندات",
  companyDocuments: "مستندات الشركة",
  saveData: "حفظ البيانات",
  commercialLicense: "الترخيص التجاري للشركة",
  signatureAccreditation: "إعتماد التوقيع",
  commissionerCard: "البطاقة المدنية للمفوض بالتوقيع",
  establishmentContract: "عقد التأسيس",
  certificateAdministration: "شهادة صاحب الحق في الإدارة من وزارة التجارة",
  ibanCertificate: "رقم الحساب المصرفى",
  letterAuthorization: "خطاب تفويض لمستخدمين - وتحديد دور كل منهم",
  company: "شركة",
  download: "تحميل",
  // Supplier Rating
  supplierRatings: "تقييمات المورد الخاص بي",
  supplierRatingsEmpty: "لم تقم بإضافة اي مراجعات",
  // Quotations
  quotations: "عروض الاسعار",
  definition: "التعريف",
  displayName: "اسم العرض",
  createdBy: "انشئ بواسطة",
  status: "الحاله",
  lastUpdate: "اخر تعديل",
  procedure: "إجراء",
  quotationsRatingsEmpty: "لم يتم العثور على أي سجلات",
  // BankNotifications
  bankNotifications: "إشعارات التحويلات البنكية المدفوعة",
  bankNotificationsHeader: "التقاط دفعتي بالتحويل المصرفي",
  orderNumber: "رقم الطلب",
  batch: "الدفعة",
  details: "التفاصيل",
  bankNotificationEmpty: "لم ترسل أي طلب لتسجيل الدفع",
  //Residents
  residents: "مرتجعاتي",
  invoiceNo: "رقم الفاتورة",
  supplier: "اسم المورد",
  residentsEmpty: "لا توجد مرتجعات",
  //SupplierCorrespondence
  supplierCorrespondence: "مراسلات المورد",
  supplierCorrespondenceEmpty: "لا توجد اتصالات",
  cancelSubscribe: "إلغاء الاشتراك",
  cancel: "الغاء الطلب",
  view: "عرض الطلب",
  delete: "مسح",
  makeDefaultAddress: "اجعل العنوان الافتراضي",
  //Orders Lists
  ordersLists: "طلباتى",
  totalOrders: "مجموع الطلبات",
  totalQuotations: "مجموع عروض الاسعار",
  totalRefunds: "مجموع المرتجعات",

  ordersListsNew: "إنشاء قائمة طلبات جديدة",
  nameDescription: "الاسم والوصف",
  products: "المنتجات",
  finalActivity: "النشاط الاخير",
  ordersListsEmpty: "لا يوجد أي قوائم طلبات",
  //Gift Card
  giftCard: "تأكد من كوبون الخصم",
  giftCardCoupon: "ادخال رقم كوبون الخصم",
  giftCardCheck: "تحقق من الحالة ",
  profileData: "الملف الشخصي كامل من حيث البيانات",
  completeInformation: "من فضلك اكمل بياناتك",
  filePlaceHolder: "قم باختيار الملف",
  postcode: "الرمز البريدى",
  apartment: "رقم الشقة",
  block_number: "رقم المبني",
  message: "اظهر الرسالة",
  name: "الاسم",
  yourMessage: "رسالتك",
  date: "التاريخ",
  productsNumber: "عدد المنتجات",
  amount: "اجمالى سعر الطلب",
  suppliersNumber: "المورد",
  paymentStatus: "حالة الطلب",
  buyMethod: "طريقة الدفع",
  Actions: "اجراء",
  payment: "الدفع",
  itemStatus: "حالة الطلب",
  itemsOrder: "عناصر الطلب",
  originalPrice: "السعر قبل الخصم",
  qty: "الكمية",
  subTotal: "المجموع الفرعى",
  discountPercent: "نسبة الخصم",
  rowTotal: "اجمالى السعر",
  orderTotal: "اجمالى الطلب",
  notFound: "عذرا المنتج غير متاح حاليا",
  couponValid: "الكوبون صحيح",
  tele: "هاتف العميل",
  paymentType: "طريقةالدفع",
  paymentCurency: "عملةالدفع",
  deleiveryFees: "مصاريف الشحن : ",
  address: "عنوان",
  orderNote: "معلومات عن الطلب",
  supplierOrder: "طلب المورد",
  paymentInfo: "معلومات الدفع",
  shoppingInfo: "معلومات الشحن ",
  return: "استرجاع",
  serial: "الرقم التسلسلى",
  searchResult: "نتيجة البحث",
  searchNoResult: "لايوجد نتائج مطابقه للبحث",
  validFor: "هذا الكوبون متاح ل ",
  discountvalue: "قيمة خصم الكوبون",
  validTill: "هذا الكوبون متاح حتى ",
  sentBy: "المرسل",
  quoteMessage: "الرسالة",
  sentdate: "التاريخ",
  quoteData: "تفاصيل تحديد السعر",
  title: "اسم المنتج",
  expiry_at: "تاريخ الانتهاء",
  send: "ارسال",
  offer: "عرض المورد",
  sendMessage: "تواصل مع المورد",
  orderSerial: "مسلسل الطلب",
  pickedAddresses: "عناوين مواقع الاستلام",
  total_shipping_fee: "مصاريف الشحن",
  total_commission: "اجمالى العمولة",
  totalDiscount: "اجمالى الخصم",
  total_price: "اجمالى السعر",
  deliveryFeesText: "عذرا لايوجد معلومات",
  noPickupData: "عفوا , هذا المورد لايدعم نقاط الاستلام",
  pay: "الدفع",
  bankTransDocs: "ملفات البنك",
  orderBack: "الرجوع لقائمة الطلبات",
  supplierName: "اسم المورد",
  lastUpdateUser: "اخر تحديثات المستخدم",
  actuons: "اجراءات",
  corresponceDetail: "مراسلات المورد و المشترى",
  notReviewd: "لم يتم تقييمه حتى الان ",
  aptNo: "رقم الشقه",
  buildingNo: "رقم المبنى",
  returnReason: "سبب الارتجاع",
  replace: "التبديل",
  refund: "استرجاع المال",
  returnImage: "صورة المنتج",
  refundMethods: "طريقة استرجاع المال",
  replaceMethods: "طلب المبادلة",
  returnRequests: "طلبات الاسترجاع",
  wallet: "محفظتى",
  returnOption: "خيار الاستبدال",
  returnStatus: "حالة الاستبدال",
  returnRequestDate: "تاريخ الاستبدال",
  updatedAt: "تاريخ التحديث",
  downloadImage: "تحميل الصوره",
  returnBack: "الرجوع الى طلبات الاسترجاع",
  returnSerial: "مسلسل طلب الاسترجاع",
  reshippingFees: "مصاريف اعادة الشحن",
  balance: "الرصيد",
  receivables: "المدخلات",
  payments: "المدفوعات",
  RefundedValue: "قيمة المرتجع",
  ValueDate: "تاريخ الاستحقاق",
  cancelReason: "سبب الغاء الطلب",
  createdAt: "وقت الطلب",
  shippingMethod: "وسيلة الشحن",
  billingInfo: "بيانات الفاتوره",
  orderSug: "طلب شراء",
  shippingInfo: "معلومات التوصيل",
  OrderDate: "تاريخ الطلب",
  rejectionReason: "سبب رفض الاسترجاع",
  cancelReason1: "غير ملائم",
  cancelReason2: "غير راضٍ عن المنتج",
  cancelReason3: "العنصر تالف",
  cancelReason4: "تم استلام عنصر خاطئ",
  cancelReason5: "غير راضٍ عن خدمة العملاء",
  cancelReason6: "الطلب مكرر",
  cancelReason7: "حجم غير صحيح",
  cancelReason8: "سبب اخر",
  Notifications: "الاشعارات",
  ReturnedNumber: "عدد منتجات المرتجع",
  noNotifications: "لا يوجد اشعارات حتى الان",
  justNow: "الان",
  second: "ث",
  ago: "منذ",
  year: "سنه",
  month: "شهر",

  week: "اسبوع",
  day: "يوم",
  hour: "ساعة",
  minute: "دقيقة",
  second2: "ثانية",
  printIno: "اطبع طلب الشراء",
  markRead: "ضع إشارة مقروء",
  readAllNotifications: "قراءة الكل",
  charge: "اشحن محفظتك",
  enterValue: "ادخل قيمة الشحن",
  limit: "حد",
  char: "حرف",
  remain: "باقى",
  lang: "اللغه",
  currency: "العمله الافتراضيه",
  defaultCountry: "البلد الافتراضيه",
  newEmail: "الايميل الجديد",
  oldEmail: "الاليميل القديم",
  newPhone: "رقم الهاتف الجديد",
  oldPhone: "رقم الهاتف القديم",
  emailVerify: "تحقق الايميل",
  phoneVerify: "تحقق الهاتف",
  newPhoneVerify: "من فضلك قم بالتحقق من هاتفك لتفعيل الحساب",
  selectLang: "اختر اللغه",
  checkMail: "من فضلك تحقق من بريدك الالكترونى",
  wallet_visa: "المحفظه + البطاقه المصرفيه",
  visaPayment: "عن طؤيق البطاقة المصفرفيه",
  walletPayment: "عن طريق المحفظه",
  standOrderName: "اسم القائمه",
  selectExistStandOrder: "اختر من القوائم المتاحه",
  dashboard: "لوحة التحكم",
  remainKnet: "يمكن دفع المبلغ المتبقي باستخدام knet أو البطاقة",
  aprovedProducts: "المنتجات الموافق عليها",
  lowInvenity: "منتجات منخفضة المخزون",
  bestSeller: "الأكثر مبيعًا",
  mostViewed: "المنتجات الأكثر مشاهدة",
  orders: "منتج",
  pending: "معلق",
  WalletRedirect: "سوف يتم توجيهك الى محفظتك خلال ٥ ثوان",
  NotificationSettings: "اعدادات الاشعارات",
  others: "أخرى",
  sms: "الرسائل النصيه",
  Mail: "البريد",
  accountDetails: "ادخل بيانات حسابك البنكى",
  buy: "اشترى",
  get: "و أحصل على ",
  buyXgetYOffer: "أشترى منتج و أحصل على اخر هدية",
  basketDeals: "عروض السله",
  basketDeal: "عرض السله",
  dailyOffers: "العرووض اليومية",
  monthlyOffers: "العروض الشهرية",
  withdraw: "سحب",
  enterwithdrawValue: "ادخل قيمة السحب",
  withdrowStatus: "حالة السحب",
  gift: "هدية",
  buyAndGetGift: "اشترى و أحصل على هدية",
  buXGetGift: "اشترى و أحصل على منتج هدية",
  free: "مجانا",
  withdrowAmount: "قيمة السحب",
  bankData: "معلومات البنك",
  reason: "السبب",
  transferred: "تم السحب",
  withdrowFile: "ملف السحب",
  basketName: "اسم السلة",
  Account_number: "رقم الحساب",
  iban: "Iban",
  bank_name: "اسم البنك",

  frequently: "التكرار",
  lastOrder: "اخر طلب",
  upload: "رفع",
  lastMessage: "اخر رسالة",
  hideMenu: "اخفاء القائمة",
  responses: "استجابات",
  pendingReturns: "عوائد معلقة",
  pendingStands: "قائمةالانتظارالمعلقة",
  selectDate: "اخر التاريخ",
  from: "من",
  to: "الى",
  dateFrom: "التاريخ من",
  dateTo: "التاريخ الى",
  deleteSelected: "حذف المختار",
  readSelected: "تعليم المختار كمقروء",
  bulkAction: "اجراء جماعى",
  selectAll: "اختيار الكل",
  dateTime: "الوقت و التاريخ",
  priceRange: "السعر من - الى ",
  priceFrom: "السعر من",
  priceTo: "السعر الى ",
  filtersApplied: "عناصر التصفيه",
  perPage: "عرض لكل صفحة",
  companyLogo: "لوجو الشركة",
  Upload: "تحميل",
  createdDate: "وقت الانشاء",
  charges: "مبالغ الشحن",
  moneyTransfer: "تحويل النقود",
  monyAmount: "المبلغ",
  uploadDamageProducts: "قم برفع صور المنتجات",
  step1: "خطوة 1",
  step2: "خطوة 2",
  myCart: "مشترياتى",
  contactInfo: "معلومات التواصل",
  clearAll: "مسح الكل",
  subtotal: "المجموع-الفرعي",
  returnDetails: "معلومات عن المنتجات",
  requestType: "نوع الطلب",
  branch: "الفرع",
  uploadedImages: "الصور المرفقه",
  printMemo: "طباعة مذكرة",
  productDetails: "معلومات المنتج",
  items: "عنصر",
  noReturnPolicy: "لا يوجد سياسة استرجاع",
  specifyQuantity: "حدد الكميات التي ترغب في إرجاعها لكل عنصر",
  Period: "المدة",
  Ordered: "الترتيب",
  Expired: "منتهي الصلاحية",
  UpdatedBySupplier: "التحديث بواسطة المورد",
  Submitted: "تاريخ الارسال",
  column: "الاعمدة",
  Pricing: "الاسعار",
  PricingPlans: "خطط الاسعار",
  'selectRegion':'اختر المنطقة',
  'filter':'تصفية'
};
