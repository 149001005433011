import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Suppliers from "../views/Suppliers.vue";
import Supplier from "../views/Supplier.vue";
import supplierProducts from "../views/supplierProducts.vue";
import ContactUs from "../views/Contact-Us.vue";
import Cart from "../views/Cart.vue";
import Wishlist from "../views/wishlist.vue";
import ForgetPassword from "../views/Forget-Password.vue";
import SingleProduct from "../views/single-Product/Single-Product.vue";
import auth from "./authRoutes";
import profileRoutes from "./profileRoutes";
import SearchResults from "../views/SearchResult.vue";
import SearchProducts from "../views/Search-Products.vue";
import QuotationDetails from "../views/profile/QuotationDetails.vue";
import VisaCheckoutDetails from "../views/Visa-Checkout-Details.vue";
import CheckoutDetails from "../views/Checkout-details.vue";
import CodBanckCheckoutDetails from "../views/COD-Bank-Ckeckout.vue";
import CheckoutBankDetails from "../views/Checkout-bank-details.vue";
import SuccessCheckout from "../views/SuccessCheckout.vue";

import returnReplace from "../views/RetuenReplace.vue";
import returnRefund from "../views/ReturnRefund.vue";

import privacyPolicy from "../views/privacyPolicy.vue";
import termsConditions from "../views/termsConditions.vue";
import faq from "../views/faq.vue";
import Clients from "../views/Clients.vue";
import StandingOrder from "../views/StandingOrder.vue";
import Partners from "../views/Partners.vue";
import NewDeals from "../views/New-deals.vue";
import basketOfferDetails from "../views/basketOfferDetails.vue";
import CheckUserValidity from "../views/CheckUserValidity.vue";

import store from "../store";
import { loadLanguageAsync } from "../i18n/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: Suppliers,
  },
  {
    path: "/suppliers/:id",
    name: "Supplier",
    component: Supplier,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/supplier-products",
    name: "supplierProducts",
    component: supplierProducts,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/details",
    name: "Details",
    component: SingleProduct,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
  },
  {
    path: "/categories/:slug",
    name: "subCategory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/categories/_slug.vue"),
  },
  {
    path: "/categories/:slug/variants",
    name: "variants",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/categories/variants.vue"
      ),
  },
  {
    path: "/best-deals",
    name: "bestDeals",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bestDeals.vue"),
  },
  {
    path: "/monthly-offers",
    name: "monthlyOffers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/monthlyOffers.vue"),
  },
  {
    path: "/viewOrderDetails",
    name: "ViewOrderDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/profile/ViewOrderDetails.vue"
      ),
  },
  {
    path: "/ReturnedRequest",
    name: "ReturnedRequest",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/profile/ReturnedRequest.vue"
      ),
  },
  {
    path: "/searchResults",
    name: "SearchResults",
    component: SearchResults,
  },
  {
    path: "/profile/quotationDetails",
    name: "QuotationDetails",
    component: QuotationDetails,
  },
  {
    path: "/Search-Products",
    name: "SearchProducts",
    component: SearchProducts,
  },
  {
    path: "/visa-checkout-details",
    name: "visaCheckoutDetails",
    component: VisaCheckoutDetails,
  },
  {
    path: "/checkout-details",
    name: "CheckoutDetails",
    component: CheckoutDetails,
  },
  {
    path: "/CodBanckCheckoutDetails",
    name: "CodBanckCheckoutDetails",
    component: CodBanckCheckoutDetails,
  },
  {
    path: "/Checkout-bank-details",
    name: "CheckoutBankDetails",
    component: CheckoutBankDetails,
  },
  {
    path: "/success-checkout",
    name: "SuccessCheckout",
    component: SuccessCheckout,
  },
  {
    path: "*",
    beforeEnter: (to, from, next) => {
      next("/404");
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/pageNotFound.vue"),
  },
  {
    path: "/return-replace",
    name: "returnReplace",
    component: returnReplace,
  },
  {
    path: "/return-refund",
    name: "returnRefund",
    component: returnRefund,
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: privacyPolicy,
  },
  {
    path: "/termsConditions",
    name: "termsConditions",
    component: termsConditions,
  },
  {
    path: "/faq",
    name: "faq",
    component: faq,
  },
  {
    path: "/Clients",
    name: "Clients",
    component: Clients,
  },
  {
    path: "/StandingOrder",
    name: "StandingOrder",
    component: StandingOrder,
  },
  {
    path: "/Partners",
    name: "Partners",
    component: Partners,
  },
  {
    path: "/new-deals",
    name: "new-deals",
    component: NewDeals,
  },
  {
    path: "/basketOfferDetails",
    name: "basketOfferDetails",
    component: basketOfferDetails,
  },
  {
    path: "/CheckUserValidity",
    name: "CheckUserValidity",
    component: CheckUserValidity,
  },
  ...auth,
  profileRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach(async (to, from, next) => {
  let guestId = localStorage.getItem("guest-id");
  let userExist = localStorage.getItem("buyerUserData");

  if (!userExist && !guestId) {
    await store.dispatch("getUserGuestId");
  }

  const lang = localStorage.getItem("lang") || "en";
  await loadLanguageAsync(lang);
  // user
  let userData = JSON.parse(localStorage.getItem("buyerUserData"));
  // doctor
  const doctorSubscriptionStatus = localStorage.getItem(
    "Doctor_subscriptions_status"
  );
  const isDoctor =
    (userData?.type == "buyer" || userData?.type == "supplier") && userData?.buyer_type == "Doctor"
      ? true
      : false;
  const isDoctorSubscribed = userData?.active_subscription;

  // b2b , supplier

  const b2bSubscriptionStatus =
    localStorage.getItem("B2b_subscription_status") ||
    localStorage.getItem("b2b_subscriptions_status");
  const isb2b =
    (userData?.type == "buyer" && userData?.buyer_type !== "Doctor") ||
    (userData?.type == "supplier" && userData?.buyer_type !== "Doctor")
      ? true
      : false;
  const isb2bSubscribed = userData?.active_subscription;

  if (userData) {
    
    if (isDoctor === "true" || isDoctor === true) {      
      if (
        (doctorSubscriptionStatus === "available" ||
          doctorSubscriptionStatus === "true" ||
          doctorSubscriptionStatus == true) &&
        (isDoctorSubscribed == null ||
          !isDoctorSubscribed ||
          isDoctorSubscribed == "null")
      ) {
        if (to.name == "Welcome") {
          next();
        } else {
          if (to.name !== "SubscripePlans") {
            // Prevent redundant redirect
            // next({ name: "SubscripePlans" });
            next();
          } else {
            next(); // If already on the SubscripePlans page, don't redirect again
          }
        }
      } else {
        next();
      }
    } else if (isb2b === "true" || isb2b === true) {
      if (
        (b2bSubscriptionStatus == "available" ||
          b2bSubscriptionStatus == "true" ||
          b2bSubscriptionStatus == true) &&
        (isb2bSubscribed == null ||
          !isb2bSubscribed ||
          isb2bSubscribed == "null")
      ) {
        if (to.name == "Welcome") {
          next();
        } else {
          if (to.name !== "SubscripePlans") {
            // Prevent redundant redirect
            // next({ name: "SubscripePlans" });
            next();
          } else {
            next(); // If already on the SubscripePlans page, don't redirect again
          }
        }
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
