<template>
  <!-- quotaions page  -->
  <div class="rfq-page">
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="profileB2b-header-table">{{ $t("profile.quotations") }}</h5>
      <div class="filters-holder d-flex">
        <div class="filter mx-2">
          <div class="perPage">
            <div class="d-flex justify-content-end align-items-center">
              <div>
                <b-form-select
                  v-model="filterPerPage"
                  class="mb-3"
                  @change="getFilteredQuotations"
                >
                  <b-form-select-option :value="null" disabled>{{
                    $t("profile.perPage")
                  }}</b-form-select-option>
                  <b-form-select-option value="">{{
                    $t("home.All")
                  }}</b-form-select-option>
                  <b-form-select-option value="5">5</b-form-select-option>
                  <b-form-select-option value="10">10</b-form-select-option>
                  <b-form-select-option value="15">15</b-form-select-option>
                  <b-form-select-option value="20">20</b-form-select-option>
                  <b-form-select-option value="25">25</b-form-select-option>
                  <b-form-select-option value="30">30</b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="filter">
          <div class="perPage">
            <div class="d-flex justify-content-end align-items-center">
              <div>
                <b-form-select
                  v-model="quotationsStatus"
                  class="mb-3"
                  @change="getFilteredQuotations"
                >
                  <b-form-select-option :value="null" disabled>{{
                    $t("profile.status")
                  }}</b-form-select-option>
                  <b-form-select-option value="Ordered">{{
                    $t("profile.Ordered")
                  }}</b-form-select-option>
                  <b-form-select-option value="Expired">{{
                    $t("profile.Expired")
                  }}</b-form-select-option>
                  <b-form-select-option value="UpdatedBySupplier">{{
                    $t("profile.UpdatedBySupplier")
                  }}</b-form-select-option>
                  <b-form-select-option value="Submitted">{{
                    $t("profile.Submitted")
                  }}</b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="filter">
          <!-- Dropdown for column filters -->
          <div class="dropdown mx-2 columnFilters">
            <b-button
              @click="showColumnData = !showColumnData"
              variant="light"
              class="btn-light dropdown-toggle"
            >
              {{ $t("profile.column") }}
            </b-button>
            <div v-if="showColumnData" class="mt-2">
              <div class="card card-body">
                <div v-for="field in fields" :key="field.key" class="mb-2">
                  <b-form-checkbox v-model="field.visible">
                    {{ field.label }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-table
      :items="items"
      :fields="visibleFields"
      stacked="lg"
      show-empty
      :empty-text="$t('profile.quotationsRatingsEmpty')"
      hover
      striped
      v-if="visibleFields.length > 0"
    >
      <template #cell(message)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-button
            @click="goQuotation(data.item.id)"
            class="mr-2 btn btn-light bg-transparent link border-main"
            ><font-awesome-icon icon="fa-regular fa-eye"
          /></b-button>
          <div class="" v-if="standing_order">
            <button
              id="show-btn"
              class="button one inactive mobile button--secondary wishlist-btn add-cart bg-dark mx-3 px-3"
              @click="
                selectId(data.item.id);
                $bvModal.show('bv-standingOrders');
              "
              v-b-tooltip.hover
              :title="$t('items.standingOrders')"
              v-if="buyerUserData"
            >
              <img
                src="@/assets/images/new-design/standing-order-sign.png"
                class="standing-order-sign"
                alt="standing-order-sign"
              />
            </button>
            <button
              v-else
              class="button one inactive mobile button--secondary wishlist-btn add-cart bg-dark mx-3 px-3"
              @click="loginFirst()"
              v-b-tooltip.hover
              :title="$t('items.standingOrders')"
            >
              <img
                src="@/assets/images/new-design/standing-order-sign.png"
                class="standing-order-sign"
                alt="standing-order-sign"
              />
            </button>
          </div>
        </div>
      </template>
      <template #cell(currency_based_price)="data">
        <span v-if="data.value"
          >{{ data.value | fixedCurrency }} {{ currency }}</span
        >
        <span v-else> - </span>
      </template>
      <template #cell(created)="data">
        <span v-if="data.value">{{ data.value | formatDate }}</span>
        <span v-else> - </span>
      </template>
      <template #cell(expiry_at)="data">
        <span v-if="data.value">{{ data.value | formatDate }}</span>
        <span v-else> - </span>
      </template>
      <template #cell(supplier_product_name)="data">
        <b-button
          v-b-modal.modal-center.data.value
          @click="goProduct(data.item.supplier_product_id)"
          class="mr-2 btn btn-light bg-transparent border-0"
          >{{ data.value }}</b-button
        >
      </template>
    </b-table>
    <div class="d-flex justify-content-start align-items-center mt-5">
      <!-- pagination for orders  -->
      <Paginate
        v-if="items"
        :total-pages="totalPages"
        :per-page="totalPages"
        :current-page="page"
        @pagechanged="onPageChange"
      />
    </div>
    <b-modal id="bv-standingOrders" size="xl" hide-footer>
      <template #modal-title>
        {{ $t("items.standingOrders") }}
      </template>
      <standing-orders :passedId="selectedId" />
    </b-modal>
  </div>
</template>

<script>
/**
 * quotaions page
 * @displayName quotaions page
 */
import StandingOrders from "@/components/global/standingOrders.vue";
import profile from "@/services/profile";
import Paginate from "@/components/global/Paginate.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

export default {
  data() {
    return {
      fields: [
        { key: "id", label: this.$t("profile.serial"), visible: true },
        { key: "quote_name", label: this.$t("profile.name"), visible: true },
        {
          key: "supplier_product_name",
          label: this.$t("profile.product"),
          visible: true,
        },
        {
          key: "created_by",
          label: this.$t("profile.createdBy"),
          visible: true,
        },
        {
          key: "request_qty",
          label: this.$t("profile.quantity"),
          visible: true,
        },
        {
          key: "currency_based_price",
          label: this.$t("profile.price"),
          visible: true,
        },
        { key: "status", label: this.$t("profile.status"), visible: true },
        {
          key: "created",
          label: this.$t("profile.createdDate"),
          visible: true,
        },
        {
          key: "expiry_at",
          label: this.$t("profile.expiry_at"),
          visible: true,
        },
        { key: "message", label: this.$t("profile.actions"), visible: true },
      ],
      items: [],
      perPage: 5,
      total: 0,
      currentPage: 1,

      page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: 10,
      enterpageno: "",
      selectedId: null,
      filterPerPage: null,
      quotationsStatus: null,
      showColumnData:false
    };
  },
  methods: {
    /**
     * @vuese
     *  login first
     */
    loginFirst() {
      Vue.swal({
        title: this.$t("singleProduct.loginFirst"),
        text: this.$t("singleProduct.registerNow"),
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$router.push("/user-register");
        }
      });
    },
    selectId(elementId) {
      this.selectedId = elementId;
    },
    /**
     * get Quotations function
     * @vuese
     */
    getQuotations() {
      profile.getQuotations(this.page , this.filterPerPage).then((resp) => {
        this.items = resp.data.items.data;
        this.total = resp.data.items.total;
        this.totalPages = Math.ceil(
          resp.data.items.total / resp.data.items.per_page
        ); // Calculate total records

        this.totalRecords = resp.data.items.total;
      });
    },
    getFilteredQuotations() {
      profile
        .getFilteredQuotations(
          this.page,
          this.filterPerPage,
          this.quotationsStatus
        )
        .then((resp) => {
          this.items = resp.data.items.data;
          this.total = resp.data.items.total;
          this.totalPages = Math.ceil(
            resp.data.items.total / resp.data.items.per_page
          ); // Calculate total records

          this.totalRecords = resp.data.items.total;
        });
    },
    /**
     * go Product function
     * @vuese
     */
    goProduct(product) {
      this.$router.push({
        path: "/details",
        query: {
          id: product,
        },
      });
    },
    goSupplierPage(supplier_id) {
      this.$router.push(`/suppliers/${supplier_id}`);
    },
    /**
     * go Quotation function
     * @vuese
     */
    goQuotation(product) {
      this.$router.push({
        path: "/profile/quotationDetails",
        query: {
          id: product,
        },
      });
    },
    /**
     * function for pagination
     * @vuese
     */
    onPageChange(page) {
      this.page = page;
      this.getQuotations(this.page);
    },
    /**
     * function for pagination
     * @vuese
     */
    onChangeRecordsPerPage() {
      this.getQuotations(this.page);
    },
    /**
     * function for pagination
     * @vuese
     */
    gotoPage() {
      if (!isNaN(parseInt(this.enterpageno))) {
        this.page = parseInt(this.enterpageno);
        this.getQuotations(this.page);
      }
    },
  },
  mounted() {
    this.getQuotations();
  },
  components: {
    Paginate,
    StandingOrders,
  },
  metaInfo: {
    title: "quotations page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
};
</script>
<style lang="scss" scoped>
.link {
  text-decoration: underline;
}
.wishlist-btn {
  padding: 5px 10px !important;
}
.columnFilters {
  position: relative;
  z-index: 9;
  button {
    overflow-anchor: none;
    background: transparent;
    border: 1px solid #ccc;
    padding: 13px;
  }
  .card.card-body {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
  }
}
select , .columnFilters button{
  min-width: 240px;
  text-align: start;
}
.columnFilters{
  .dropdown-toggle::after{
    position: absolute !important;
    right: 15px;
    top: 50%;
  }
}
</style>
