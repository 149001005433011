<template>
  <div>
    <!-- buyer register page  -->
    <!-- buyer form component page  -->
    <DoctorForm />
  </div>
</template>
<script>
import DoctorForm from "@/components/pages/DoctorForm.vue";
export default {
  components: {
    DoctorForm,
  },
  mounted() {
    setTimeout(() => {
      if (this.b2b_buyer_registration !== true) {
        this.$router.push("/");
      }
    }, 3000);
    if (!this.b2b_status) {
      this.$router.push("/");
    }
  },
  metaInfo: {
    title: "Register Page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
};
</script>
